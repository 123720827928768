<template>
  <div>
    <div>
      <div
        class="my-2"
      >
        <b-row>
          <b-col
            md="3"
          >
            <b-form-group
              label="First Name"
              label-for="v-name"
            >
              <b-form-input
                id="v-name"
                v-model="filters['first_name']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
          >
            <b-form-group
              label="Email"
              label-for="v-name"
            >
              <b-form-input
                id="v-name"
                v-model="filters['email']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            class="d-block d-sm-flex flex-wrap justify-content-start align-items-center mb-1 mb-md-0"
          >
            <b-button
              v-can="'create-staff'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="custom-button-color"
              @click="onClickCreate()"
            >
              <feather-icon icon="PlusIcon" />
              Add New
            </b-button>
          </b-col>
          <b-col
            class="d-flex justify-content-end align-items-center mb-1 mb-md-0"
          >
            <ReportTableButtons
              :items="rows"
              :json_fieldz="json_fields"
              :name="`${role.name} - Page ${page}.xls`"
              :bulkname="`${role.name}.xls`"
              :fetch="fetchStaffListNoPagination"
              @refresh="onClickRefresh"
            />
          </b-col>
        </b-row>
      </div>

      <!-- table -->
      <b-overlay
        id="overlay-background"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-table
          :fields="fields"
          :items="rows"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          hover
          responsive
          striped
          show-empty
          sticky-header="100vh"
          class="hide-vertical-scroll"
        >
          <template #empty>
            <TableDataFetching
              :rows="rows"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(warehouses)="data">
            <div
              class="text-primary"
              @click="onClickShowWarehouses(data.value)"
            >
              {{ data.value.length }} Assigned Warehouse{{ data.value.length > 1 ? 's' : '' }}
            </div>
          </template>
          <template
            #cell(rider_commission)="data"
            @click="showData('role.id')"
          >
            <div
              class="text-primary"
            >
              {{ data.item.is_commission_active === true ? 'Yes' : 'No' }}
            </div>
          </template>
          <template #cell(rate_card)="data">
            <div class="d-flex align-items-center">
              <div class="font-weight-bolder ml-1">
                <router-link
                  v-if="data.item.rate_card_id != null"
                  :to="{ name: 'view-rate-card', params: { rateCardType: 'rider', id: data.item.rate_card_id }}"
                >
                  {{ data.value }}
                </router-link>
                <router-link
                  v-else
                  class="text-muted"
                  to=""
                >
                  N/A
                </router-link>
              </div>
            </div>
          </template>
          <template #cell(is_active)="data">
            <div v-if="data.value === true">
              <b-badge :variant="status[1][1]">
                {{ status[0][1] }}
              </b-badge>
            </div>
            <div v-if="data.value === false">
              <b-badge :variant="status[1][0]">
                {{ status[0][0] }}
              </b-badge>
            </div>
          </template>
          <template #cell(action)="data">
            {{ data.value }}
            <b-dropdown
              no-caret
              toggle-class="text-decoration-none"
              :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle mr-25"
                  icon="MoreVerticalIcon"
                  size="12"
                />
              </template>
              <b-dropdown-item
                v-can="'show-staff'"
                @click="onClickUpdate(data.item.id)"
              >
                <feather-icon icon="ToolIcon" />
                Manage Staff
              </b-dropdown-item>
              <b-dropdown-item
                v-can="'password-reset-staff'"
                @click="onClickResetPassword(data.item)"
              >
                <feather-icon icon="LockIcon" />
                Reset User Password
              </b-dropdown-item>
              <b-dropdown-item
                v-if="store.getters.permissions.filter(permission => permission === 'create-chatrooms').length > 0 && data.item.id !== currentUser.id"
                @click="chatWithUser(data.item)"
              >
                <feather-icon icon="MessageSquareIcon" />
                Message User
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row
            cols="12"
          >
            <b-col
              class="d-flex align-items-center justify-content-sm-start"
              md="3"
            >
              <label class="width-75">Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block width-100"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              class="d-flex align-items-center justify-content-sm-end"
              md="9"
            >
              <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :per-page="perPage"
                :total-rows="(meta.total)? meta.total : 0"
                class="mb-0 mt-1 mt-sm-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-overlay>
    </div>
    <create-staff-modal ref="createStaffModal" />
    <update-staff-modal ref="updateStaffModal" />
    <reset-password-staff-modal ref="resetPasswordStaffModal" />
    <warehouse-modal ref="warehouse_modal" />
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'
import CreateStaffModal from '@/components/staff-manage/CreateStaffModal.vue'
import UpdateStaffModal from '@/components/staff-manage/UpdateStaffModal.vue'
import ResetPasswordStaffModal from '@/components/staff-manage/ResetPasswordStaffModal.vue'
import WarehouseModal from '@/components/Warehouse/WarehouseModal.vue'

const ResourceRepository = RepositoryFactory.get('resource')
const ChatRepository = RepositoryFactory.get('chat')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BPagination,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormInput,
    UpdateStaffModal,
    CreateStaffModal,
    ResetPasswordStaffModal,
    WarehouseModal,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  props: {
    role: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      store,
      avatarText,
      filters: {},
      meta: {},
      dataLoading: false,
      sort: '',
      perPage: 50,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      dir: false,
      // End Modal Properties
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          thClass: 'd-none',
          tdClass: 'd-none',
        }, {
          key: 'first_name',
          label: 'First Name',
          sortable: true,
          tdClass: 'text-left',
        },
        {
          key: 'last_name',
          label: 'Last Name',
          sortable: true,
          tdClass: 'text-left',
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'role.name',
          label: 'Role',
          sortable: true,
        },
        {
          key: 'warehouses',
          label: 'Warehouses',
          sortable: false,
        },
        {
          key: 'rate_card',
          label: 'Rate Card',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          key: 'rider_commission',
          label: 'Commission',
          sortable: false,
        },
        {
          key: 'is_active',
          label: 'Status',
          sortable: false,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      json_fields: {
        'First Name': {
          field: 'first_name',
          callback: value => `"${value}"`,
        },
        'Last Name': {
          field: 'last_name',
          callback: value => `"${value}"`,
        },
        Email: 'email',
        Role: {
          field: 'role.name',
          callback: value => `"${value}"`,
        },
        Warehouses: {
          field: 'warehouses',
          callback: value => `"${value.map(n => n.name)}"`,
        },
        'Rate Card': 'rate_card',
        Active: 'is_active',
      },
      rows: [],
      warehouses: [],
      searchTerm: '',
      currentUser: {},
      status: [{
        0: 'Inactive',
        1: 'Active',
      },
      {
        0: 'light-danger',
        1: 'light-success',
      }],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchStaffList()
      },
    },
    deep: true,
    perPage() {
      this.fetchStaffList()
    },
    page() {
      this.fetchStaffList()
    },
  },
  mounted() {
    if (this.role.id !== 7) {
      this.fields = this.fields.filter(f => f.key !== 'rate_card')
      this.fields = this.fields.filter(f => f.key !== 'rider_commission')
      delete this.json_fields['Rate Card']
    }
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('current_user'))
    this.fetchStaffListNoPagination()
  },
  methods: {
    async fetchStaffList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await ResourceRepository.getAllStaffListByRoleId(this.page, this.role.id, this.filterQuery, this.perPage, this.sort))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchStaffListNoPagination() {
      try {
        const { data } = (await ResourceRepository.getAllStaffListByRoleIdNoPagination(this.role.id, this.filterQuery, this.sort))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async chatWithUser(user) {
      try {
        const payload = {
          participant_id: user.id,
          participant_type: 'staff',
          name: `${this.currentUser.first_name} ${this.currentUser.last_name} , ${user.first_name} ${user.last_name}`,
          type: 'Private',
        }
        const { data } = (await ChatRepository.createChatroom(payload)).data
        this.$router.push({ name: 'chat-r2', params: { chatroomId: data.chatroom_id || data.id } })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onClickShowWarehouses(warehouses) {
      this.$refs.warehouse_modal.openModal(warehouses)
    },
    onClickUpdate(id) {
      this.$refs.updateStaffModal.openModal(id)
    },
    onClickResetPassword(data) {
      this.$refs.resetPasswordStaffModal.openModal(data)
    },
    onClickCreate() {
      this.$refs.createStaffModal.openModal()
    },
    filterQueryUpdate() {
      this.fetchStaffList()
    },
    handleChangePage(page) {
      this.page = page
      this.fetchStaffList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.fetchStaffList()
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../@core/scss/vue/libs/vue-select.scss';

.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
