<template>
  <b-overlay
    :show="loading"
    blur="10px"
    opacity="0.30"
    rounded="sm"
    variant="light"
  >
    <b-card>
      <b-tabs
        ref="staffTabBar"
        card
        pills
      >
        <b-tab
          v-for="role in roles"
          :key="role.id"
        >
          <template #title>
            <span
              class="font-weight-bold"
            >
              {{ role.name }}
            </span>
          </template>
          <staff-list :role="role" />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import StaffList from './StaffList.vue'

const RoleRepository = RepositoryFactory.get('role')

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BOverlay,
    StaffList,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      roles: [],
      loading: false,
    }
  },
  async mounted() {
    await this.fetchRoleList()
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('staffTabBar', 'staffTabIndex')
  },
  async created() {
    await this.fetchRoleList()
  },
  methods: {
    async fetchRoleList() {
      try {
        this.loading = true
        const { data } = (await RoleRepository.getRoleListNoPagination())
        this.roles = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
