<template>
  <b-modal
    id="modal-staff-add"
    ref="modal"
    v-model="open"
    hide-footer
    no-close-on-backdrop
    size="lg"
    title="ADD NEW STAFF"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="createStaffForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(createStaff)"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                class="required"
                label="First Name"
                label-cols-md="4"
                label-for="h-fName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    id="fname"
                    v-model="form.first_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="First Name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Last Name"
                label-cols-md="4"
                label-for="h-lName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Last Name"
                  rules=""
                >
                  <b-form-input
                    id="lnames"
                    v-model="form.last_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="First Name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="required"
                label="Email"
                label-cols-md="4"
                label-for="h-email"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                    type="email"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="required"
                label="Password"
                label-cols-md="4"
                label-for="h-email"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Password"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="required"
                label="Mobile"
                label-cols-md="4"
                label-for="h-pCode"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Mobile"
                  rules="required"
                >
                  <b-form-input
                    id="h-mobile"
                    v-model="form.phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Mobile (Required for SMS notifications)"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="required"
                label="Role"
                label-cols-md="4"
                label-for="h-state"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="role"
                  rules="required"
                >
                  <v-select
                    id="role"
                    v-model="form.role_id"
                    :options="roles"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select Role"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="isRiderCommissionShowing"
              cols="12"
            >
              <b-form-group
                label="Rider Commission"
                label-cols-md="4"
                label-for="h-state"
              >
                <b-form-checkbox
                  v-model="form.is_commission_active"
                  class="custom-control-success"
                  style="margin-top: 10px;"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Warehouse"
                label-cols-md="4"
                label-for="h-state"
              >
                <v-select
                  v-model="selected_warehouse_ids"
                  :options="warehouses"
                  :reduce="option => option.id"
                  multiple
                  deselect-from-dropdown
                  label="name"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
                <div class="mt-1">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    @click="onClickWarehouseSelectAll()"
                  >
                    Select All
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="ml-1"
                    size="sm"
                    variant="outline-primary"
                    @click="onClickWarehouseDeselectAll()"
                  >
                    Deselect All
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                v-any="['activate-staff', 'deactivate-staff']"
                label="Active Status"
                label-cols-md="4"
                label-for="h-state"
              >
                <b-form-checkbox
                  v-model="form.is_active"
                  :value="true"
                  class="custom-control-success"
                  style="margin-top: 10px;"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.role_id === 7 && finance.commission_invoice.rider_commission_invoice.enabled"
              cols="12"
              class="mb-1"
            >
              <h4>
                Rider Commission Invoice Setting
              </h4>
              <invoice-date-calendar
                :selected-type.sync="selectedType"
                :selected-day.sync="selectedDay"
                :period.sync="period"
                :applied-date.sync="appliedDate"
              />
            </b-col>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1 custom-button-color"
                  type="submit"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  BFormCheckbox,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import InvoiceDateCalendar from '@/components/InvoiceCalendar/InvoiceDateCalendar.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const StaffRepository = RepositoryFactory.get('staff')
const RoleRepository = RepositoryFactory.get('role')
const FinanceSettingsRepository = RepositoryFactory.get('financeSetting')

function initialState() {
  const currentDate = new Date()

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed.
  const day = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if needed.

  const formattedDate = `${year}-${month}-${day}`

  return {
    open: false,
    id: null,
    form: {
      is_active: false,
      is_commission_active: false,
    },
    modalLoading: false,
    loading: false,
    warehouses: [],
    roles: [],
    selected_warehouse_ids: [],
    selectedType: 'Day',
    selectedDay: 'Mon',
    period: 1,
    appliedDate: formattedDate,
    finance: {
      commission_invoice: {
        rider_commission_invoice: {
          enabled: false,
        },
      },
    },
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckbox,
    BRow,
    BOverlay,
    vSelect,
    InvoiceDateCalendar,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    isRiderCommissionShowing() {
      return this.form.role_id === 7
    },
  },
  methods: {
    async openModal() {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.modalLoading = true
      this.getWarehouseList()
      await this.fetchRoles()
      await this.readFinanceSetting()
      this.modalLoading = false
    },
    async readFinanceSetting() {
      try {
        this.finance = localStorage.getItem('finance_setting') ? JSON.parse(localStorage.getItem('finance_setting')) : (await FinanceSettingsRepository.getFinanceSettingsResource()).data
        if (!localStorage.getItem('finance_setting')) localStorage.setItem('finance_setting', JSON.stringify(this.finance))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchRoles() {
      try {
        const { data } = (await RoleRepository.getRoleListNoPagination()).data
        this.roles = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getWarehouseList() {
      try {
        this.warehouses = localStorage.getItem('warehouse_list') ? JSON.parse(localStorage.getItem('warehouse_list')) : (await ResourceRepository.getWarehouseListForDropDown()).data.data
        if (!localStorage.getItem('warehouse_list')) localStorage.setItem('warehouse_list', JSON.stringify(this.warehouses))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async createStaff() {
      this.modalLoading = true
      try {
        this.form.warehouse_ids = this.selected_warehouse_ids
        if (this.form.role_id === 7) {
          this.form.invoice_period = this.period
          this.form.invoicing_cycle = this.selectedType
          this.form.next_invoice_generate_date = this.appliedDate

          if (this.selectedType === 'Week') {
            this.form.day_of_week = this.selectedDay
          }
        }
        await StaffRepository.createStaff(this.form)
        this.showSuccessMessage('Staff created successfully')
        this.open = false
        this.$parent.onClickRefresh()
        if (this.roles.filter(role => role.id === this.form.role_id && role.name === 'Rider').length > 0) localStorage.removeItem('rider_list')
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    onClickWarehouseSelectAll() {
      this.selected_warehouse_ids = this.warehouses.map(n => n.id)
    },
    onClickWarehouseDeselectAll() {
      this.selected_warehouse_ids = []
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/required.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
